<template>
   <v-app style="background-color: #F5F5F5;">
     <menu-bar
     ></menu-bar>

     <v-img style="min-height: 30vh; min-width: 100vh;"
         src="/api/static/frontpage/dolphins.jpeg"
     >
       <div class="fancy-text" style="color: #F9F6EE; position: absolute; bottom: 30%; margin-left: 5%;
font-size: 2vw;">
          {{$t('frontpage.title_l1')}} <br />
          {{$t('frontpage.title_l2')}} <br />
          {{$t('frontpage.title_l3')}} <br />
       </div>
       <animated-box
           top="44"
           left="40.5"
           width="12"
           height="23"
           :title="$t('frontpage.animated_box8')"
           subtitle="0.89"
       ></animated-box>
       <animated-box
           top="35"
           left="56.5"
           width="8.9"
           height="12.2"
           :title="$t('frontpage.animated_box4')"
           subtitle="0.94"
       ></animated-box>
       <animated-box
           top="65"
           left="78.5"
           width="2.8"
           height="5.7"
           :title="$t('frontpage.animated_box_bird')"
           subtitle="0.98"
       ></animated-box>
       <!-- <div style="position: absolute; bottom: 7.5%; left: 5%;">
          <v-row align="center" justify="start">
            <v-col cols="auto" style="margin: 10px;">
              <v-btn color="white" @click.native="goToRegister">
                Create account
              </v-btn>
            </v-col>
            <v-col cols="auto" style="margin: 10px;">
              <v-btn color="primary" @click.native="goToLogin">
                Login
              </v-btn>
            </v-col>
          </v-row>              
       </div> -->
      </v-img>


     <v-main style="background-color: #F5F5F5; padding-top: 9%; padding-bottom: 6%;">
      <row class="">
        <col3 style="position: relative; display: flex; justify-content: center; align-items: center;">
          <div style="position: absolute; top: 0" >
            <img width="50px" src="/api/static/frontpage/video.svg"/>
            Video
          </div>
          <div style="position: absolute; top: 13%; left: 22%" >
            <img width="50px" src="/api/static/frontpage/images.svg"/>
            Images
          </div>
          <div style="position: absolute; top: 27%; left: 50%" >
            <img width="50px" src="/api/static/frontpage/collaborate.svg"/>
            Collaborate
          </div>


          <div style="
            margin-top: 200px;
            width: 60%; 
            aspect-ratio: 1 / 1;
            background-color: #3475B4; 
            color: #FFFFFF; 
            border: 1px solid #CCCCCC; 
            display: flex; 
            align-items: center; 
            justify-content: center;">
            <div class="fancy-text" style="font-size: 1.3em">
              Create & manage datasets
            </div>
          </div>
        </col3>
        <col3 style="position: relative; display: flex; justify-content: center; align-items: center;">

          <div style="position: absolute; top: 0%" >
            <img width="50px" src="/api/static/frontpage/segmentation.svg"/>
            Segmentation
          </div>
          <div style="position: absolute; top: 20%; left: 50%" >
            <img width="50px" src="/api/static/frontpage/keypoints.svg"/>
            Keypoints
          </div>
          <div style="position: absolute; top: 23%; left: 19%" >
            <img width="50px" src="/api/static/frontpage/boxes.svg"/>
            Boxes
          </div>

          <div style="
            margin-top: 200px;
            width: 60%; 
            aspect-ratio: 1 / 1;
            background-color: #3475B4; 
            color: #FFFFFF; 
            border: 1px solid #CCCCCC; 
            display: flex; 
            align-items: center; 
            justify-content: center;">
            <div class="fancy-text" style="font-size: 1.3em">
              Train vision models
            </div>
          </div>
        </col3>
        <col3 style="position: relative; display: flex; justify-content: center; align-items: center;">

          <div style="position: absolute; top: 0" >
            <img width="50px" src="/api/static/frontpage/count.svg"/>
            Count
          </div>
          <div style="position: absolute; top: 22%; left: 60%" >
            <img width="50px" src="/api/static/frontpage/alert.svg"/>
            Alert
          </div>
          <div style="position: absolute; top: 21%; left: 26%" >
            <img width="50px" src="/api/static/frontpage/track.svg"/>
            Track
          </div>

          <div style="
            margin-top: 200px;
            width: 60%; 
            aspect-ratio: 1 / 1;
            background-color: #3475B4; 
            color: #FFFFFF; 
            border: 1px solid #CCCCCC; 
            display: flex; 
            align-items: center; 
            justify-content: center;">
            <div class="fancy-text" style="font-size: 1.3em">
              Deploy <br /> on mobile, edge or gpu
            </div>
          </div>
        </col3>
        <col3 style="position: relative; display: flex; justify-content: center; align-items: center;">

          <div style="position: absolute; top: 23%; left: 26%" >
            <img width="50px" src="/api/static/frontpage/visualize.svg"/>
            Visualize
          </div>

          <div style="
            margin-top: 200px;
            width: 60%; 
            aspect-ratio: 1 / 1;
            background-color: #3475B4 ; 
            color: #FFFFFF; 
            border: 1px solid #CCCCCC; 
            display: flex; 
            align-items: center; 
            justify-content: center;">
            <div class="fancy-text" style="font-size: 1.3em">
              Monitor performance
            </div>
          </div>
        </col3>
      </row>          
    </v-main>


      <v-content style="background-color: #F5F5F5; margin-top: 3%">
      <row>
          <col8>
            <img class="centered-image"  src="/api/static/examples/agouti.png" />
          </col8>
          <col4>
            <div class="fancy-text" style="margin-top: 20%; font-size: 1em; text-align: left;">
              Agouti.eu serves automatic analysis of millions of camera trap images, saving users countless hours of boring work
              <br /><br />
              Box21 is used to train & deploy vision models detecting and classifying species around the globe. 
            </div>
          </col4>
      </row>          
      </v-content>

      <v-main style="background-color: #F5F5F5; padding-top: 10%">
      <row class="">
      
        <col6>
          <div class="fancy-text" style="font-size: 2.0vw; margin-top: 30%">
            Deploy on mobile or edge devices <br /><br />Realtime and offline
          </div>
        </col6>
        <col6 style="text-align: center;">
          <img src="https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/10ea5998-e881-4bb6-845f-db5e6898bfe5.gif?ClientID=sulu&Date=1741791340&Signature=91b4e54e383603464a5afdd2b8257db3bbcfb4cc" />
        </col6>
      </row>          
    </v-main>

    <v-content style="background-color: #F5F5F5; margin-top: 5%; margin-bottom: 5%">
      <row class="">
        <col6>
          <img class="centered-image" src="/api/static/examples/chitals.png" />
        </col6>
        <col6>
          <div class="fancy-text" style="font-size: 2vw; margin-top: 20%">
            Use Arcface enabled architectures to detect simularities in images
          </div>
        </col6>
      </row>          
    </v-content>

    <v-content style="background-color: #F5F5F5; margin-top: 5%; margin-bottom: 5%">
      <row class="">
        <col4>
          <div class="fancy-text" style="font-size: 2vw; margin-top: 35%">
            Detect boxes, classifications and keypoints in one go
          </div>
        </col4>
        <col8>
          <img class="centered-image" src="/api/static/examples/reddeer.jpeg" />
        </col8>
      </row>          
    </v-content>

      <v-content style="background-color: #F5F5F5; margin-bottom: 10%">
      <row>
          <col6>
            <div class="fancy-text" style="font-size: 2.0vw; margin-top: 40%">
            Trusted by
            </div>
          </col6>
          <col6>
            <img width="80%" style="margin-top: 15%; margin-bottom: 10%" src="/api/static/wageningen-university-and-research.svg" />
            <img width="30%" src="/api/static/frontpage/logo-enovation.svg"/>
            <img width="35%" style="padding-left: 15%" src="/api/static/sortmybricks.png"/>
          </col6>
      </row>          
      </v-content>

<!-- 


      <v-content style="background-color: #F5F5F5;">
      <row>
          <col6>
            <img class="centered-image" width="90%" src="/api/static/examples/chitals.PNG" />
          </col6>
          <col6>
            <div style="text-align: center;">Detecting and counting individual deers in Nepal's national park to monitor wildlife populations and support conservation efforts.</div>
          </col6>
      </row>          
      </v-content>

      <v-content style="background-color: #F5F5F5;">
      <row>
          <col6>
            <img width="90%" src="/api/static/wageningen-university-and-research.svg" />
          </col6>
          <col6>
            <img width="90%" src="/api/static/examples/agouti.PNG" />
          </col6>
      </row>          
      </v-content>

      <v-content style="background-color: #E38229;">

          <row>
              <col12>
                  <v-card style="margin-left: 20%; margin-bottom: 10%; width: 60%; margin-top: -60px;" elevation="10">
                      <v-img style="" src="/api/static/examples/reddeer.jpeg"></v-img>
                  </v-card>
              </col12>
          </row>          
      </v-content>
      <v-content style="padding-top: 7%; padding-bottom: 5%; background-color: #7cacd4">   
       <row>
         <col12>
           <v-layout align-center justify-center>
             <div class='examples-title text-h2' style="text-align: center; color: black; font-weight: bold;
font-size: 2.5vw;" >
               {{$t('frontpage.examples_title')}}
             </div>
           </v-layout>
         </col12>
       </row>
       <row style="margin-top: 50px;">
         <col4>
           <example-project
            :title="$t('frontpage.example1_title')"
            :description="$t('frontpage.example1_description')"
            organisation="Himalayan Tiger Foundation"
            image="/api/static/examples/individual-animal-recognition.png"
           >
           </example-project>
         </col4>
         <col4>
           <example-project
               :title="$t('frontpage.example2_title')"
               :description="$t('frontpage.example2_description')"
               organisation="Wageningen University"
               image="/api/static/examples/animal-classification.png"
           >
           </example-project>
         </col4>
         <col4>
           <example-project
               :title="$t('frontpage.example3_title')"
               :description="$t('frontpage.example3_description')"
               organisation="Creative builder"
               image="/api/static/examples/lego.png"
           >
           </example-project>
         </col4>
       </row>
         <br />
         <br />
     </v-content> -->
   </v-app>
</template>

<script>
// import Col6 from "@/components/layout/Col6";
import Row from "@/components/layout/Row";
// import Col3 from "@/components/layout/Col3";
// import Row12 from "@/components/layout/Row12";
import Col4 from "@/components/layout/Col4";
import Col3 from "@/components/layout/Col3";
import Col8 from "@/components/layout/Col8";
import Col6 from "@/components/layout/Col6";
import MenuBar from "@/components/layout/MenuBar.vue";
import AnimatedBox from "@/components/front_page/AnimatedBox.vue";
export default {
name: "FrontPage",
  components: {AnimatedBox, MenuBar, Col4, Col6, Col3, Row, Col8},//, Col6, Row12, Col3
  methods: {
    join () {
      if(this.project == '') {
        alert('Please describe your project')
        return
      }
      if(!this.email.includes('@')) {
        alert('Please add a valid email')
        return
      }

      this.$set(this, 'form_send', true)
      this.$api.user.join_waiting_list(this.email, this.project)
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToRegister() {
      this.$router.push({ path: "/login", query: { tab: "register" } });
    },    
  },
  data () {
  return {
        email: '',
        project: '',
        form_send: false,
    }
  },
}
</script>

<style scoped>

.fancy-text {
  text-align: center;
  /* font-family: 'Playfair Display', serif; */
  font-size: 1.8em;
  max-width: 60%;
  margin: 0 auto;
  font-weight: bold;
}

.centered-image {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.button-container {
  margin-top: 200px;
}

.container {
  position: relative;
  height: 400px;
}

.centered-element {
  margin: 0;
  position: absolute;
  top: 72%;
  left: 47%;
  width: 11%;
  height: 18%;
  transform: translate(-50%, -50%);
}
.centered-element-2 {
  margin: 0;
  position: absolute;
  top: 54%;
  left: 56%;
  width: 10%;
  height: 12%;
  /*transform: translate(-50%, -50%);*/
}

.centered-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

.centered-element-3 {
  margin: 0;
  position: absolute;
  top: 78%;
  left: 78.5%;
  width: 3%;
  height: 6%;
  /*transform: translate(-50%, -50%);*/
}

@media (max-width: 600px) {
  .hometext {
    font-size: 4vw !important;
  }
  .examples-title {
    font-size: 5vw !important;
    margin-bottom: -60px;
  }
}
</style>